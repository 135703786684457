import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
    className,
    ...props
}) => {

    const classes = classNames(
        'brand',
        className
    );

    return (
        <div
            {...props}
            className={classes}
            style={{ display: 'flex', flexDirection: 'row' }}
        >
            <Link to="/">
                <Image
                    src={require('./../../../assets/images/logo1.svg')}
                    alt="Open"
                    width={32}
                    height={32} />
            </Link>
            <Link to="/">
                禾新電訊
            </Link>
        </div>
    );
}

export default Logo;