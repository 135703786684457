import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container-sm">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <div style={{ display: 'flex' }}>
                            <div data-reveal-value="20px" data-reveal-delay="800">
                                <Image
                                    className="has-shadow"
                                    src={require('./../../assets/pictures/papa.png')}
                                    width={300}
                                    height={250} />
                            </div>
                            <div>
                                <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                                    經營目標
                                </h1>
                                <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                                    能源數位轉型的專家
                                </h1>
                                <div className="container-xs">
                                    <p className={"m-0 mb-32 reveal-from-bottom"} data-reveal-delay="400">
                                        專注能源創新及能源數位轉型
                                    </p>
                                    <p className={"m-0 mb-32 reveal-from-bottom"} data-reveal-delay="400">
                                        透過IT及OT技術，協助客戶邁向數位化
                                    </p>
                                </div>                         
                                <div className="reveal-from-bottom" data-reveal-delay="600">
                                    <ButtonGroup>
                                        <Button tag="a" color="primary" wideMobile href="https://www.taiwanbuying.com.tw/ShowCCTerminateNew.ASP?CompanyName=%E7%A6%BE%E6%96%B0%E9%9B%BB%E8%A8%8A%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&CompId=52448305" target="_blank">
                                            過往實績
                                        </Button>
                                        <Button tag="a" color="dark" wideMobile href="https://www.104.com.tw/company/1a2x6bl3pr" target="_blank">
                                            人才招募
                                        </Button>
                                    </ButtonGroup>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
                        <Image
                            className="has-shadow"
                            src={require('./../../assets/pictures/main.jpg')}
                            width={896}
                            height={504} />
                    </div>
                </div>
            </div>
        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;