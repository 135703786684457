import React from 'react';
import classNames from 'classnames';

const FooterContact = ({
    className,
    ...props
}) => {

    const classes = classNames(
        'footer-nav-contact',
        className
    );

    return (
        <nav
            {...props}
            className={classes}
        >
            <ul className="list-reset">
                <li>
                    <h5 style={{ margin: 'unset' }}>聯絡方式:</h5>
                </li>
                <li>
                    <h5 style={{ margin: 'unset' }}>電話: 02-89310355</h5>
                </li>
                <li>
                    <h5 style={{ margin: 'unset' }}>傳真: 02-89310356</h5>
                </li>
                <li>
                    <h5 style={{ margin: 'unset' }}>聯絡人: 姜曉莉</h5>
                </li>
                <li>
                    <h5 style={{ margin: 'unset' }}>地址: 116 台北市文山區羅斯福路五段125號2樓</h5>
                </li>
            </ul>
        </nav>
    );
}

export default FooterContact;