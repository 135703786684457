import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: '成功案例',
        // paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    Web-Based SCADA繪製工具
                                </h3>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/web-based1.png')}
                                    width={528}
                                    height={396} />
                                <p></p>
                                <Image
                                    src={require('./../../assets/pictures/web-based2.png')}
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    Web-Based SCADA即時資訊
                                </h3>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/web-based-real1.png')}
                                    width={528}
                                    height={396} />
                                <p></p>
                                <Image
                                    src={require('./../../assets/pictures/web-based-real2.png')}
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    再生能源規劃暨預測系統
                                </h3>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/RenewableEnergy.png')}
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    資料可視化及數據分析
                                </h3>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/dataVisable.png')}
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    SCADA整合及GIS加值應用
                                </h3>
                                <p className="m-0" style={{ whiteSpace: 'pre-wrap' }}>
                                    {`OT< -- >OA 資料串接整合並結合地理圖資平台\n進行視覺化加值應用開發`}
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/gis2.png')}
                                    width={528}
                                    height={396} />
                                <p></p>
                                <Image
                                    src={require('./../../assets/pictures/gis3.png')}
                                    width={528}
                                    height={396} />
                                <p></p>
                                <Image
                                    src={require('./../../assets/pictures/gis4.png')}
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    智慧型模擬訓練系統
                                </h3>
                                <p className="m-0" style={{ whiteSpace: 'pre-wrap' }}>
                                    {`結合前後端的技術以及串接專業的電力模擬軟體，\n讓客戶可以使用網頁的方式進行畫圖及模擬訓練，\n為客戶省下成本並且增加效率`}
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/otsGraph.jpg')}
                                    alt="Features split 02"
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    智慧變流器通訊功能之研究
                                </h3>
                                <p className="m-0" style={{ whiteSpace: 'pre-wrap' }}>
                                    {`研究IEC61850、MODBUS及SUNSPEC通訊協定串接，並且開發出相關模擬器以利客戶進行設備驗收前的模擬測試，增加驗收效率以及正確性`}
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/pvgateway.jpg')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    台電輔助服務平台
                                </h3>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/61850trans3.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                                <p></p>
                                <Image
                                    src={require('./../../assets/pictures/61850trans1.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                                <p></p>
                                <Image
                                    src={require('./../../assets/pictures/61850trans2.png')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    DNP3認證
                                </h3>
                                <p className="m-0" style={{ whiteSpace: 'pre-wrap' }}>
                                    {`取得ETC DNP3的認證證書`}
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/DNP3.jpg')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                                <h3 className="mt-0 mb-12">
                                    IEC61850 認證
                                </h3>
                                <p className="m-0" style={{ whiteSpace: 'pre-wrap' }}>
                                    通過UCA IEC61850 MMS認證
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    src={require('./../../assets/pictures/61850.jpg')}
                                    alt="Features split 03"
                                    width={528}
                                    height={396} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;